.com-text-show-by-single {
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-align: center;
  .text-cursor {
    margin-left: 6px;
    animation: cursor-ani 700ms ease infinite;
    
    @keyframes cursor-ani {
 
      /* 开始 */
      from {
        opacity: 1;
      }
 
      to {
        opacity: 0;
      }
    }
  }
  @media screen and (max-width: 768px) {
    // font-size: 16px;
  }
}
