@import "../../static/css/var.scss";

.com-pro-modal-main {
  .modal-title {
    margin-bottom: 30px;
  }
  .content-main {
    background-color: $color-light-black-transparent;
    padding: 30px;
    border-radius: 8px;
    min-height: 400px;
    align-content: flex-start;
    justify-content: space-between;
  }
  .pro-item {
    width: 270px;
    height: 320px;
    // border-bottom: 1px solid $color-green;
    margin-bottom: 10px;
    .img {
      width: 268px;
      height: 268px;
      object-fit: cover;
      border-radius: 20px
    }
    .title {
      margin: 10px;
    }
    .des {
      font-size: 12px;
      margin-bottom: 4px;
      height: 16px;
      overflow: hidden;
      @include text-gray;
    }
    .more {
      font-size: 16px;
      color: $color-green;
    }
  }
  // .pro-item:nth-child(3n) {
  //   margin-right: 0;
  // }
  // @media screen and (min-width: 768px) {
  // }

  @media screen and (max-width: 768px) {
    .modal-title {
      margin-bottom: 16px;
    }
    .content-main {
      padding: 18px 10px;
      min-height: 340px;
    }
    .pro-item {
      width: 32%;
      height: 128px;
      margin-bottom: 0;
      .title  {
        margin: 4px;
      }
      .des {
        margin-bottom: 8px;
      }
      .more{
        font-size: 12px;
      }
      .img {
        border-radius: 10px;
        width: 90px;
        height: 90px;
      }
      // width: 49%;
    }
    // .pro-item:nth-child(2n) {
    //   margin-right: 0;
    // }
  }
}
