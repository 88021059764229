@import "./var.scss";

/*
  * 鼠标悬浮样式
  */
.pointer {
  cursor: pointer;
}

.hover-white:hover {
  @include text-white();
}
/*
  * 文字样式
  */

.text-gray {
  @include text-gray();
}
.text-white {
  @include text-white();
}
.text-green {
  color: $color-green;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-bold {
  font-weight: bold;
}
/**
背景色
**/
.bg-light-black {
  background-color: $color-light-black;
}

/**
字体颜色粗细等
**/
// 菜单-标题
.menu-item-text {
  font-size: var(--menuItemTextSize);
  font-weight: var(--menuItemTextWeight);
  color: var(--menuItemTextColor);
}

.menu-item-text-active {
  font-size: var(--menuItemTextActiveSize);
  font-weight: var(--menuItemTextActiveWeight);
  color: var(--menuItemTextActiveColor);
}
.menu-item-text:hover {
  font-size: var(--menuItemTextHoverSize);
  font-weight: var(--menuItemTextHoverWeight);
  color: var(--menuItemTextHoverColor);
}
// 问候语
.show-by-single-text {
  font-size: var(--showBySingleTextSize);
  font-weight: var(--showBySingleTextWeight);
  color: var(--showBySingleTextColor);
}
.show-by-single-cursor {
  border-right: 2px solid var(--showBySingleCursorColor);
}
// 底部链接
.bottom-link-title-text {
  font-size: var(--bottomLinkTitleTextSize);
  font-weight: var(--bottomLinkTitleTextWeight);
  color: var(--bottomLinkTitleTextColor);
}
.bottom-link-item-text {
  font-size: var(--bottomLinkItemTextSize);
  font-weight: var(--bottomLinkItemTextWeight);
  color: var(--bottomLinkItemTextColor);
}
.bottom-link-item-text:hover {
  font-size: var(--bottomLinkItemTextHoverSize);
  font-weight: var(--bottomLinkItemTextHoverWeight);
  color: var(--bottomLinkItemTextHoverColor);
}
// 产品框
.pro-modal-title-text {
  font-size: var(--proModalTitleTextSize);
  font-weight: var(--proModalTitleTextWeight);
  color: var(--proModalTitleTextColor);
}
.pro-modal-item-text {
  font-size: var(--proModalItemTextSize);
  font-weight: var(--proModalItemTextSize);
  color: var(--proModalItemTextSize);
}
// 下载页-标题
.download-title-text {
  font-size: var(--downloadTitleTextSize);
  font-weight: var(--downloadTitleTextWeight);
  color: var(--downloadTitleTextColor);
}
.download-des-text {
  font-size: var(--downloadDesTextSize);
  font-weight: var(--downloadDesTextWeight);
  color: var(--downloadDesTextColor);
}
// 下载页-搜索-展示
.ant-select-selection-item {
  font-size: 14px;
  font-weight: 500;
  color: #a6adb4;
}
// 下载页-搜索-下拉框
.download-search-text {
  .ant-select-item {
    font-size: 14px;
    font-weight: 500;
    color: #a6adb4;
  }
  .ant-select-item-option-selected {
    font-size: 14px;
    font-weight: 1000 !important;
    color: #fff !important;
  }
}
// 下载页-表格-标题
.ant-table-thead {
  .ant-table-cell {
    font-size: var(--downloadTableTitleTextSize);
    font-weight: var(--downloadTableTitleTextWeight) !important;
    color: var(--downloadTableTitleTextColor) !important;
  }
}
// 下载页-表格-内容
.ant-table-tbody {
  .ant-table-cell {
    font-size: var(--downloadTableContentTextSize);
    font-weight: var(--downloadTableContentTextWeight);
    color: var(--downloadTableContentTextColor);
  }
}
// 下载页-表格-内容-下载按钮
.download-table-btn-text {
  font-size: var(--downloadTableBtnTextSize);
  font-weight: var(--downloadTableBtnTextWeight);
  color: var(--downloadTableBtnTextColor);
}
.download-table-btn-text:hover {
  font-size: var(--downloadTableBtnTextHoverSize);
  font-weight: var(--downloadTableBtnTextHoverWeight);
  color: var(--downloadTableBtnTextHoverColor) !important;
}

// 搜索页-产品名称
.search-product-name-text {
  font-size: var(--searchProductNameTextSize);
  font-weight: var(--searchProductNameTextWeight);
  color: var(--searchProductNameTextColor);
}
.search-product-des-text {
  font-size: var(--searchProductDesTextSize);
  font-weight: var(--searchProductDesTextWeight);
  color: var(--searchProductDesTextColor);
}
// 产品详情-购买栏-产品名称
.product-buy-prod-name-text {
  font-size: var(--productBuyProdNameTextSize);
  font-weight: var(--productBuyProdNameTextWeight);
  color: var(--productBuyProdNameTextColor);
}
.product-buy-text {
  font-size: var(--productBuyTextSize);
  font-weight: var(--productBuyTextWeight);
  color: var(--productBuyTextColor);
}
// 产品详情-购买栏-购买背景色
.product-buy-bg-color {
  background-color: var(--productBuyBgColor);
}

// 产品详情-图文-产品名称
.product-prod-name-text {
  font-size: var(--productProdNameTextSize);
  font-weight: var(--productProdNameTextWeight);
  color: var(--productProdNameTextColor);
}
// 产品详情-图文
.product-feature-title-text {
  font-size: var(--productFeatureTitleTextSize);
  font-weight: var(--productFeatureTitleTextWeight);
  color: var(--productFeatureTitleTextColor);
}
.product-feature-des-text {
  font-size: var(--productFeatureDesTextSize);
  font-weight: var(--productFeatureDesTextWeight);
  color: var(--productFeatureDesTextColor);
}
// 产品详情-技术规格-分隔标题
.product-spec-title-text {
  font-size: var(--productSpecTitleTextSize);
  font-weight: var(--productSpecTitleTextWeight);
  color: var(--productSpecTitleTextColor);
}
.product-spec-item-title-text {
  font-size: var(--productSpecItemTitleTextSize);
  font-weight: var(--productSpecItemTitleTextWeight);
  color: var(--productSpecItemTitleTextColor);
}
.product-spec-item-des-text {
  font-size: var(--productSpecItemDesTextSize);
  font-weight: var(--productSpecItemDesTextWeight);
  color: var(--productSpecItemDesTextColor);
}

@media screen and (max-width: 768px) {
  // 标题
  .menu-item-text,
  .menu-item-text:hover {
    font-size: var(--menuItemTextH5Size);
    font-weight: var(--menuItemTextH5Weight);
    color: var(--menuItemTextH5Color);
  }

  .menu-item-text-active {
    font-size: var(--menuItemTextActiveH5Size);
    font-weight: var(--menuItemTextActiveH5Weight);
    color: var(--menuItemTextActiveH5Color);
  }
  // 问候语
  .show-by-single-text {
    font-size: var(--showBySingleTextH5Size);
    font-weight: var(--showBySingleTextH5Weight);
    color: var(--showBySingleTextH5Color);
  }
  .show-by-single-cursor {
    border-right: 2px solid var(--showBySingleCursorH5Color);
  }
  // 底部链接
  .bottom-link-title-text {
    font-size: var(--bottomLinkTitleTextH5Size);
    font-weight: var(--bottomLinkTitleTextH5Weight);
    color: var(--bottomLinkTitleTextH5Color);
  }
  .bottom-link-item-text,
  .bottom-link-item-text:hover {
    font-size: var(--bottomLinkItemTextH5Size);
    font-weight: var(--bottomLinkItemTextH5Weight);
    color: var(--bottomLinkItemTextH5Color);
  }
  // 产品框
  .pro-modal-title-text {
    font-size: var(--proModalTitleTextH5Size);
    font-weight: var(--proModalTitleTextH5Weight);
    color: var(--proModalTitleTextH5Color);
  }
  .pro-modal-item-text {
    font-size: var(--proModalItemTextH5Size);
    font-weight: var(--proModalItemTextH5Size);
    color: var(--proModalItemTextH5Size);
  }
  // 下载页-标题
  .download-title-text {
    font-size: var(--downloadTitleTextH5Size);
    font-weight: var(--downloadTitleTextH5Weight);
    color: var(--downloadTitleTextH5Color);
  }
  .download-des-text {
    font-size: var(--downloadDesTextH5Size);
    font-weight: var(--downloadDesTextH5Weight);
    color: var(--downloadDesTextH5Color);
  }
  // 下载页-搜索-展示
  .ant-select-selection-item {
    font-size: 14px;
    font-weight: 500;
    color: #a6adb4;
  }
  // 下载页-搜索-下拉框
  .download-search-text {
    .ant-select-item {
      font-size: 14px;
      font-weight: 500;
      color: #a6adb4;
    }
    .ant-select-item-option-selected {
      font-size: 14px;
      font-weight: 1000 !important;
      color: #fff !important;
    }
  }
  // 下载页-表格-标题
  .ant-table-thead {
    .ant-table-cell {
      font-size: var(--downloadTableTitleTextH5Size);
      font-weight: var(--downloadTableTitleTextH5Weight) !important;
      color: var(--downloadTableTitleTextH5Color) !important;
    }
  }
  // 下载页-表格-内容
  .ant-table-tbody {
    .ant-table-cell {
      font-size: var(--downloadTableContentTextH5Size);
      font-weight: var(--downloadTableContentTextH5Weight);
      color: var(--downloadTableContentTextH5Color);
    }
  }
  // 下载页-表格-内容-下载按钮
  .download-table-btn-text {
    font-size: var(--downloadTableBtnTextH5Size);
    font-weight: var(--downloadTableBtnTextH5Weight);
    color: var(--downloadTableBtnTextH5Color);
  }
  .download-table-btn-text:hover {
    font-size: var(--downloadTableBtnTextHoverH5Size);
    font-weight: var(--downloadTableBtnTextHoverH5Weight);
    color: var(--downloadTableBtnTextHoverH5Color) !important;
  }
  // 搜索页-产品名称
  .search-product-name-text {
    font-size: var(--searchProductNameTextH5Size);
    font-weight: var(--searchProductNameTextH5Weight);
    color: var(--searchProductNameTextH5Color);
  }
  .search-product-des-text {
    font-size: var(--searchProductDesTextH5Size);
    font-weight: var(--searchProductDesTextH5Weight);
    color: var(--searchProductDesTextH5Color);
  }
  // 产品详情-购买栏-产品名称
  .product-buy-prod-name-text {
    font-size: var(--productBuyProdNameTextH5Size);
    font-weight: var(--productBuyProdNameTextH5Weight);
    color: var(--productBuyProdNameTextH5Color);
  }
  .product-buy-text {
    font-size: var(--productBuyTextH5Size);
    font-weight: var(--productBuyTextH5Weight);
    color: var(--productBuyTextH5Color);
  }
  // 产品详情-购买栏-购买背景色
  .product-buy-bg-color {
    background-color: var(--productBuyBgH5Color);
  }
  // 产品详情-图文-产品名称
  .product-prod-name-text {
    font-size: var(--productProdNameTextH5Size);
    font-weight: var(--productProdNameTextH5Weight);
    color: var(--productProdNameTextH5Color);
  }
  // 产品详情-图文
  .product-feature-title-text {
    font-size: var(--productFeatureTitleTextH5Size);
    font-weight: var(--productFeatureTitleTextH5Weight);
    color: var(--productFeatureTitleTextH5Color);
  }
  .product-feature-des-text {
    font-size: var(--productFeatureDesTextH5Size);
    font-weight: var(--productFeatureDesTextH5Weight);
    color: var(--productFeatureDesTextH5Color);
  }
  // 产品详情-技术规格-分隔标题
  .product-spec-title-text {
    font-size: var(--productSpecTitleTextH5Size);
    font-weight: var(--productSpecTitleTextH5Weight);
    color: var(--productSpecTitleTextH5Color);
  }
  .product-spec-item-title-text {
    font-size: var(--productSpecItemTitleTextH5Size);
    font-weight: var(--productSpecItemTitleTextH5Weight);
    color: var(--productSpecItemTitleTextH5Color);
  }
  .product-spec-item-des-text {
    font-size: var(--productSpecItemDesTextH5Size);
    font-weight: var(--productSpecItemDesTextH5Weight);
    color: var(--productSpecItemDesTextH5Color);
  }
}

/*  -- flex弹性布局 -- */

.flex {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

.basis-xs {
  flex-basis: 8%;
}

.basis-sm {
  flex-basis: 16%;
}

.basis-df {
  flex-basis: 20%;
}

.basis-lg {
  flex-basis: 60%;
}

.basis-xl {
  flex-basis: 80%;
}

.flex-sub {
  flex: 1;
}

.flex-twice {
  flex: 2;
}

.flex-treble {
  flex: 3;
}

.flex-direction {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: flex-center;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

.align-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
