@import "./static/css/main.scss";

.ant-popover-inner-content {
  @include text-gray;
}

.ant-modal-mask {
  // background-color: rgba(0, 0, 0, 0.85) !important;
  backdrop-filter: blur(2px); //毛玻璃属性
}

.ant-modal-content {
  background: none !important;
  padding: 0 !important;
}

// .ant-popover-arrow {
//   display: none !important;
// }

.App {
  min-height: 100vh;
  .app-header-wrap {
    z-index: 1;
    position: sticky;
    top: 0;
  }
  .app-header {
    max-width: 1100px;
    margin: auto;

    .app-heder-h5 {
      display: none;
    }

    .app-header-pc {
      .ant-menu {
        width: 100%;
      }

      .ant-menu-item {
        margin-left: 50px;
      }

      .lan-search {
        height: 64px;

        .language,
        .search {
          height: 60px;
          line-height: 60px;
        }
        .line {
          width: 1px;
          height: 20px;
          background: $color-gray;
          margin: 0 8px;
        }
        .search {
          img {
            vertical-align: text-top;
          }
        }
      }
    }
  }

  .content {
    .scroll-wrap {
      padding: 10px 0;
    }
    .nav-h5 {
      display: none;
    }
  }
  .footer {
    max-width: 1100px;
    margin: auto;
    .footer-item {
      text-align: left;
      padding-right: 10px;
    }
    .sub-item {
      margin-top: 14px;
      img {
        height: 30px;
        vertical-align: middle;
        margin-right: 2px;
      }
    }
    .move-img {
      width: 80%;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      margin: auto;
      .sub-item {
        margin-top: 8px;
        img {
          height: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .app-header {
      .app-heder-h5 {
        display: flex;
      }

      .app-header-pc {
        display: none;
      }
      .search {
        img {
          vertical-align: middle;
        }
      }
    }
    .content {
      .nav-h5 {
        display: flex;
        border-bottom: 1px solid $color-gray;
        height: 40px;
        .menu-item-text {
          flex: 1;
          height: 100%;
          line-height: 40px;
        }
      }
    }
    .footer {
      width: 90%;
      margin: auto;
      .sub-item {
        margin-top: 8px;
        img {
          height: 20px;
        }
      }
    }
    .ant-layout-footer {
      padding: 50px 0;
    }
  }
}
