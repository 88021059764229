@import "../../static/css/var.scss";
.page-download {
  max-width: 1100px;
  margin: auto;
  .title-wrap {
    // background: url("../../static/imgs/download/bg.jpg") no-repeat;
    width: 100%;
    height: 300px;
    background-size: cover !important;
    background-position: center;
    .title {
      margin-bottom: 30px;
    }
  }
  .pro-select {
    padding: 20px 0;
    .select-item {
      width: 190px;
      margin-right: 10px;
    }
  }
  .table-wrap {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .title-wrap {
      height: 160px;
      font-size: 12px;
      .title {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
    .pro-select {
      padding: 10px;
      .select-item {
        width: 150px;
        margin-right: 6px;
      }
    }
  }
}
