$color-green: #44d62c;
$color-gray: #8c8e91;
$color-light-black: #222222;
$color-light-black-transparent: rgba(34, 34, 34, 0.9);

/*
  *背景色
  */
@mixin bg-black {
  background-color: #111 !important;
}
@mixin text-gray {
  color: $color-gray !important;
}
@mixin text-white {
  color: #fff !important;
}
