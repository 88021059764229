@import "../../static/css/var.scss";
.page-search {
  width: 100%;
  padding: 10px 0;
  @include bg-black();
  .sear-wrap {
    max-width: 1100px;
    margin: auto;
    .sear-input {
      width: 300px;
      margin-bottom: 10px;
    }
    .sear-tip {
      margin-bottom: 10px;
    }
    .sear-item {
      background-color: $color-light-black;
      margin-right: 2%;
      flex-basis: 32%;
      margin-bottom: 30px;
      .img-wrap {
        width: 100%;
        position: relative;
        padding-top: 100%;
        .img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .bt-wrap {
        padding: 10px 20px;
        .title {
          margin-bottom: 14px;
          // font-size: 20px;
        }
        .des {
          max-width: 80%;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .sear-wrap {
      padding: 0 10px;
      .sear-input,
      .sear-tip {
        width: 96%;
        margin-left: 2%;
      }
      .sear-item {
        flex-basis: 49%;
        margin-bottom: 10px;
        // .img {
        //   width: 100%;
        // }
        .bt-wrap {
          padding: 8px 12px;
          .title {
            margin-bottom: 12px;
            // font-size: 14px;
          }
          .des {
            // font-size: 12px;
          }
        }
      }
      .sear-item:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .sear-wrap {
      .sear-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
