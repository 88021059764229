@import "../../static/css/var.scss";
.page-product {
  .buy-wrap {
    height: 50px;
    width: 100%;
    .buy {
      max-width: 1200px;
      height: 100%;
      margin: auto;
      padding: 0 50px;
    }
    // .buy-prod-names {
    //   font-size: 18px;
    //   font-weight: bold;
    // }
  }
  .content {
    .prod-name {
      .prod-logo {
        width: 100%;
      }
      .name {
        // font-size: 20px;
        padding: 20px 0;
      }
    }
    .prod-item {
      max-width: 1100px;
      margin: auto;
      .title {
        // font-size: 36px;
        margin-bottom: 10px;
      }
      .img {
        margin: 20px 0;
        width: 100%;
      }
    }
    .prod-specifications {
      max-width: 1100px;
      margin: auto;
      padding-top: 30px;
      .title {
        // font-size: 20px;
        margin-bottom: 10px;
      }
      .spec-item {
        min-height: 40px;
        padding: 10px 0;
        border-bottom: 1px solid #333;
        .item-left {
            // font-size: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 10px;
    .buy-wrap {
      .buy {
        padding: 0;
      }
      // .buy-prod-names {
      //   font-size: 14px;
      // }
    }
    .content {
      .prod-name {
        .name {
          // font-size: 12px;
          padding: 10px 0;
        }
      }
      .prod-item {
        .title {
          // font-size: 26px;
          margin-bottom: 6px;
        }
        .des {
          font-size: 12px;
        }
        .img {
          margin: 10px 0;
        }
      }
      .prod-specifications {
        padding-top: 0;
        .title {
            // font-size: 16px;
        }
        .spec-item {
            padding: 4px 0;
            .item-left {
                flex-basis: 100%;
                // font-size: 14px;
            }
            .item-right {
                margin-top: 4px;
                flex-basis: 100%;
                // font-size: 12px;
            }
          }
      }
    }
  }
}