@import "../../static/css/var.scss";
.page-home {
  margin-bottom: 30px;
  position: relative;
  .banner-img {
    width: 100%;
    height: 56.25vw;
    object-fit: contain;
  }
  .img-left,
  .img-right {
    position: absolute;
    top: 23vw;
    opacity: 0.2;
  }
  .img-left {
    left: 2vw;
  }
  .img-right {
    right: 2vw;
  }
  @media screen and (max-width: 768px) {
    .banner-img {
      width: 100%;
      height: 100vw;
      object-fit: cover;
    }
    .img-left,
    .img-right {
      top: 46.6vw;
      width: 10vw;
      height: 10vw;
    }
  }
}
